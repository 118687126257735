<template>
    <b-nav-item-dropdown right no-caret class="mailbox">
        <template #button-content>
            <div class="h-100 d-flex align-items-center">
                <b-avatar :src="usuario.foto || '../../../../assets/images/users/1.jpg'" class="mr-2" />
                <div class="text-valorem-branco botao-perfil-nomes">
                    <div class="font-weight-normal">{{usuario.nome}}</div>
                    <div class="font-weight-light font-12">{{retornarPerfil(usuario.perfil).text}}</div>
                </div>
            </div>
        </template>
    
        <b-dropdown-text>
            <div class="d-flex align-items-center justify-content-between py-2">
                <div class="ml-3">
                    <h4 class="mb-0">{{usuario.nome}}</h4>
                    <div class="font-weight-light">{{ email }}</div>
                    <b-badge v-if="usuario" :variant="retornarPerfil(usuario.perfil).variante">{{retornarPerfil(usuario.perfil).text}}</b-badge>
                </div>
                <b-avatar :src="usuario.foto || '../../../../assets/images/users/1.jpg'" class="mr-2" size="72px" />
            </div>
        </b-dropdown-text>

        <b-dropdown-item href="#" class="d-flex align-items-center" @click="abrirMinhaConta">
            <feather type="user" class="feather-sm mr-2 mb-n1 text-valorem-ciano"></feather>
            {{ $t("GERAL.MINHA_CONTA") }}
        </b-dropdown-item>

        <b-dropdown-item href="#" class="d-flex align-items-center" id="show-modal" @click="abrirFecharModal(true)">
            <feather type="lock" class="feather-sm mr-2 mb-n1  text-valorem-ciano"></feather>
            {{ $t("GERAL.TROCAR_SENHA") }}
        </b-dropdown-item>

        <b-dropdown-item href="#" class="d-flex align-items-center" @click="logout">
            <feather type="log-out" class="feather-sm mr-2 mb-n1"></feather>
            {{ $t("GERAL.SAIR") }}
        </b-dropdown-item>

        <modal-altera-senha :mostrarModal="mostrarModal" @fecharModal="abrirFecharModal(false)" />
    </b-nav-item-dropdown>
    
</template>
<script>
import { LOGOUT } from "@/store/autenticacao.module";
import ModalAlteraSenha from "@/layouts/components/ModalAlteraSenha";
import JwtService from "@/common/utils/jwt";
import helpers from "@/common/utils/helpers";
export default {
    components : {
        ModalAlteraSenha,   
    },
    data(){
        return {
            mostrarModal: false,
            menuFiltro : "",
        }
    },
    methods : {  
        
        abrirMinhaConta(){
        this.$router.push({ name: "minha-conta" });
        },      
        abrirFecharModal(abrirFechar) {
            this.mostrarModal = abrirFechar;
        },
        logout() {
            this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: "Login" }))
        },
        retornarPerfil : function(perfil){
            return helpers.getEnum(perfil)
        }
    },
    computed : {
        nomeUsuario() {
            return `${JwtService.buscarNome()}`;
        },
        email() {
            return JwtService.buscarEmail();
        },
        usuarioId(){
            return JwtService.buscarId();
        },
        usuario : function(){
            return this.$store.state.autenticacao.usuario;
        },

    },
}
</script>
<style lang="scss">
    .botao-perfil-nomes{
        line-height: initial !important;
    }
    .form-group.menu-estabelecimento {
        margin: 0 !important;
        legend{
            margin: 0;
            padding: 0;
        }
        .multiselect__tags{
            border:none !important;
        }
    }
</style>