export default [
  {
    id: "2",
    title: "Dashboard",
    i18n: "MENU.DASHBOARD",
    permissao: null,
    to: "/dashboard",
    iconExt: require("../../assets/scss/icons/assinaturas/valorem_ciano/dashboard.svg"),
    esconder: true,
  },
  {
    id: "3",
    title: "Estabelecimentos",
    i18n: "MENU.ESTABELECIMENTOS",
    permissao: "Estabelecimento",
    to: "/estabelecimentos",
    iconExt: require("../../assets/scss/icons/assinaturas/valorem_ciano/estabelecimento.svg"),
    iconeBranco: require("../../assets/scss/icons/assinaturas/valorem_branco/estabelecimento.svg"),
  },
  {
    id: "4",
    title: "Usuários",
    permissao: "Usuario",
    i18n: "MENU.USUARIOS",
    iconExt: require("../../assets/scss/icons/assinaturas/valorem_ciano/usuarios.svg"),
    iconeBranco: require("../../assets/scss/icons/assinaturas/valorem_branco/usuarios.svg"),
    to: "/usuarios",
  },
  {
    id: "5",
    iconExt: require("../../assets/scss/icons/assinaturas/valorem_ciano/relatorios.svg"),
    iconeBranco: require("../../assets/scss/icons/assinaturas/valorem_ciano/relatorios.svg"),
    title: "Transações",
    i18n: "MENU.TRANSACOES",
    permissao: "Usuario",
    to: "/transacoes",
  },
  {
    id: "6",
    iconExt: require("../../assets/scss/icons/assinaturas/valorem_ciano/assinaturas.svg"),
    iconeBranco: require("../../assets/scss/icons/assinaturas/valorem_ciano/assinaturas.svg"),
    title: "Relatórios",
    i18n: "MENU.RELATORIOS",
    permissao: "Usuario",
    to: "/relatorios",
  }
];
